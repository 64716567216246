import React from 'react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import device from '../../helpers/breakpoints';

const ImageBackground = styled(BackgroundImage)`
  background-position: center;
  background-size: cover;
  height: 45vh;

  + * {
    margin-top: 0;
  }

  @media ${device.laptop} {
    height: 50vh;
  }

  @media ${device.laptop} {
    height: 65vh;
  }

  @media ${device.desktop} {
    height: 75vh;
  }
`;

const ImageCompOne = ({ moveUp, marginBottom, extraText, children }) => {
  const query = graphql`
    query {
      image: file(relativePath: { eq: "werkmet.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  const Wrapper = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    top: ${moveUp === 'true' ? -4 : 0}rem;
    margin-bottom: ${marginBottom === 'none' ? 0 : 3}rem;
    z-index: -1;

    @media ${device.laptop} {
      top: ${moveUp === 'true' ? -6 : 0}rem;
      margin-bottom: ${marginBottom === 'none' ? 0 : 6}rem;
    }
  `;

  const TextWrapper = styled.div`
    display: ${extraText === 'true' ? 'block' : 'none'};
    max-width: 100%;
    position: relative;
    top: 2rem;
    z-index: 9;
    padding: 0 2rem;
    line-height: 2rem;
    text-align: center;

    @media ${device.tablet} {
      position: absolute;
      top: 35%;
      left: 60%;
      transform: translate(-50%, -50%);
      padding: 0;
      max-width: 418px;
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={data => (
        <Wrapper>
          <ImageBackground
            Tag="section"
            fluid={data.image.sharp.fluid}
            fadeIn="soft"
          />
          <TextWrapper>
            <p>{children}</p>
          </TextWrapper>
        </Wrapper>
      )}
    ></StaticQuery>
  );
};

export default ImageCompOne;

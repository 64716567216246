import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import CompTitle from '../helpers/comp-title';
import PostPreview from '../components/post-preview';
import { StaticQuery, graphql } from 'gatsby';
import Anchor from '../helpers/anchor';

const Artikels = () => {
    const query = graphql`
        query {
            allMdx(sort: {fields: [frontmatter___date], order: DESC}, limit: 10) {
                nodes {
                    frontmatter {
                        title
                        slug
                        authors
                        date
                    }
                    excerpt
                }
            }
        }
    `;

    const Wrapper = styled.div`
        background-color: #fff;
        margin: 0 auto;
        margin-left: 2rem;
        margin-right: 2rem;
        padding-bottom: 3rem;
    `;

    return (
        <StaticQuery
            query={query}
            render={data => {
                const posts = data.allMdx.nodes.map(post => ({
                    title: post.frontmatter.title,
                    authors: post.frontmatter.authors,
                    slug: post.frontmatter.slug,
                    date: post.frontmatter.date,
                    excerpt: post.excerpt
                }));

                return (
                    <Wrapper id="artikels">
                        <Anchor id="artikels-anchor"></Anchor>
                        <CompTitle css={css`
                            display: block;
                            margin-bottom: 2rem;
                            text-align: center;
                            `}>Artikelen
                        </CompTitle>

                        {posts.map(post => (
                            <PostPreview key={post.slug} post={post} />
                        ))}
                    </Wrapper>
                );
            }}>
        </StaticQuery>
    )
}

export default Artikels;

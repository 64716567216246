import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { graphql, StaticQuery } from 'gatsby';
import LayoutContainer from '../helpers/layoutcontainer';
import device from '../helpers/breakpoints';
import CompTitle from '../helpers/comp-title';
import Anchor from '../helpers/anchor';
import CtaButtonInverted from '../helpers/buttons/cta-button-inverted';

const Kernwaarden = () => {
  const query = graphql`
    query {
      fitte: file(relativePath: { eq: "fitte.svg" }) {
        publicURL
      }
    }
  `;

  const Wrapper = styled.div`
    position: relative;
    background-color: #5e9e4e;
    margin: 0 auto 3rem;
    margin-bottom: -20px
    flex-flow: row wrap;
    padding: 3rem 0;

    @media ${device.laptop} {
      padding: 6rem 0;
      margin: 6rem auto;
    }

    ::before {
      border-style: solid;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      width: 0;
      z-index: 1;
      border-color: transparent transparent #5e9e4e;
      top: -1.46vw;
      border-width: 0 100vw 1.5vw 0;
      z-index: 9;
    }

    ::after {
      border-style: solid;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      width: 0;
      z-index: 1;
      border-color: transparent #5e9e4e transparent;
      bottom: -1.46vw;
      border-width: 0 100vw 1.5vw;
      z-index: 9;
    }
  `;

  const ExtendedLayoutContainer = styled(LayoutContainer)`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  `;

  const Left = styled.div`
    margin: 0 auto 3rem;
    z-index: 5;
    padding: 0;
    flex: 1 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.laptop} {
      padding: 0 2rem 0 0;
      margin: 0 auto;
      flex: 1 0 40%;
    }
  `;

  const Right = styled.div`
    margin: 0 auto;
    z-index: 5;
    padding: 0;
    flex: 1 0 100%;
    align-self: flex-end;

    @media ${device.laptop} {
      padding: 0 0 0 2rem;
      flex: 1 0 60%;
    }
  `;

  const ExtendedTitle = styled(CompTitle)`
    color: #fff;
  `;

  const Paragraph = styled.p`
    line-height: 1.6;
    color: #fff;
  `;

  const Content = styled.div`
    display: block;
    position: relative;
    z-index: 10;
    color: #fff;
  `;

  const ImageWrap = styled.div`
    margin: 0 auto;

    img {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <Wrapper id="kernwaarden">
          <Anchor id="kernwaarden-anchor"></Anchor>
          <ExtendedLayoutContainer>
            <Left>
              <Content>
                <ExtendedTitle>Kernwaarden</ExtendedTitle>
                <Paragraph>
                  XPRTZ staat voor FITTE Professionals, die actuele kennis
                  combineren met softskills en passende methodieken. XPRTZ nemen
                  eigenaarschap, stimuleren openheid, samenwerking en
                  kennisdeling, om onze klanten optimaal van dienst te zijn.
                </Paragraph>
                <CtaButtonInverted
                  href="/checkup"
                  css={css`
                    position: relative;
                    top: 1.5rem;
                  `}
                >
                  Start de samenwerking
                </CtaButtonInverted>
              </Content>
            </Left>
            <Right>
              <ImageWrap>
                <img src={data.fitte.publicURL} alt="fitte" />
              </ImageWrap>
            </Right>
          </ExtendedLayoutContainer>
        </Wrapper>
      )}
    ></StaticQuery>
  );
};

export default Kernwaarden;

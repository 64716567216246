import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../components/layout';
import Hero from '../components/hero';
import Nav from '../components/nav';
import Banner from '../components/banner';
import Visie from '../components/visie';
import Kernwaarden from '../components/kernwaarden';
import ImageCompOne from '../components/image-comps/image-comp-one';
import KlantenGrid from '../components/klanten-grid';
import Artikels from '../components/artikels';
import Techradar from '../components/techradar';
import Insta from '../components/Insta';
import Contact from '../components/contact';
import Footer from '../components/footer';

export const query = graphql`
  {
    site {
      siteMetadata {
        name
        title
        description
      }
    }
    image: file(relativePath: { eq: "header-linkedin01.jpg" }) {
      publicURL
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "symboltext-xprtz-white.svg" }) {
      publicURL
    }
    arrowdown: file(relativePath: { eq: "arrowdown.svg" }) {
      publicURL
    }
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0.5rem;
`;

const Slice = styled.div``;

const RenderBody = ({ title, image, logo, arrowdown }) => (
  <>
    <Hero image={image} logo={logo} arrowdown={arrowdown} />
    <Nav />
    <Slice>
      <Container>
        <Banner title={title} />
      </Container>
    </Slice>
    <Slice>
      <Container>
        <Visie />
      </Container>
    </Slice>
    <Slice>
      <Kernwaarden />
    </Slice>
    <Slice id="werkenmet">
      <ImageCompOne moveUp="true" marginBottom="none" extraText="true">
        <span>
          Samenwerken met klanten, én met elkaar, aan opdrachten die passen bij
          de actuele expertise van onze developers. Vanuit onze passie voor .NET
          staat kennis ontwikkeling en delen bovenaan. Met deze attitude leren
          wij continue en kunnen wij de beste oplossingen realiseren.
        </span>
      </ImageCompOne>
    </Slice>
    <Slice>
      <Container>
        <KlantenGrid />
      </Container>
    </Slice>
    <Slice>
      <Container>
        <Artikels />
      </Container>
      <Techradar />
      <Container>
        <Insta spaceBottom="big" />
      </Container>
    </Slice>
    <Slice>
      <Contact />
    </Slice>
    <Footer />
  </>
);

export default ({ data }) => {
  return (
    <Layout
      metadata={{ ...data.site.siteMetadata, image: data.image.publicURL }}
    >
      <RenderBody
        title={data.site.siteMetadata.title}
        image={data.image}
        logo={data.logo}
        arrowdown={data.arrowdown}
      />
    </Layout>
  );
};

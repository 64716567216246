import React from 'react';
import { css } from '@emotion/core';
import ReadLink from './read-link';
import PostInformation from './post-information';
import Moment from 'react-moment';
import 'moment/locale/nl';
import Authors from './authors';

const PostPreview = ({ post }) => (
    <article
        css={css`
            margin-top: 0.75rem;
            padding-bottom: 1rem;
            :first-of-type {
                margin-top: 1rem;
            }
        `}
    >
        <h3>{post.title}</h3>
        <PostInformation>
            <Moment format="DD MMMM YYYY">{post.date}</Moment> | <Authors authors={post.authors} />
        </PostInformation>
        <p>{post.excerpt}</p>
        <ReadLink to={post.slug}>Lees dit artikel &rarr;</ReadLink>
    </article>
);

export default PostPreview;

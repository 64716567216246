import React from 'react';
import styled from '@emotion/styled';
import BackgroundImage from 'gatsby-background-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import device from '../helpers/breakpoints';

const Hero = ({image, logo, arrowdown}) => {
  const Wrapper = styled.div`
    width: 100%;    
  `;

  const Logo = styled.img`
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50vw;
      max-width: 150px;
      max-height: 50vh;
    transform: translate(-50%, -60%);

    @media ${device.tablet} {
      width: 30vw;
      max-width: 720px;
    }
  `;

  const ScrollIndicator = styled.img`
    display: block;
    position: absolute;
    bottom: 20px;
    left: 50%;
    height: 40px;
    width: 40px;
    margin-left: -20px;

    animation: bounce 2s infinite;

    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-30px);
      }
      60% {
        transform: translateY(-15px);
      }
    }

    @media ${device.tablet} {
      left: 50%;
    }
  `;

  const ImageBackground = styled(BackgroundImage)`
    background-position: center;
    background-size: cover;
    height: 100vh;

    + * {
      margin-top: 0;
    }
  `;

  return (
    <Wrapper>
      <ImageBackground
        Tag="section"
        fluid={image.sharp.fluid}
        fadeIn="soft">
          {logo && <Logo src={logo.publicURL} />}
      </ImageBackground>
      <AnchorLink href="#banner-anchor">
        <ScrollIndicator src={arrowdown.publicURL} />
      </AnchorLink>            
    </Wrapper>
  );
};

export default Hero;

import styled from '@emotion/styled';
import device from '../breakpoints';

const CtaButton = styled.a`
  color: #5e9e4e;
  text-decoration: none;
  position: relative;
  font-weight: 700;
  text-transform: lowercase;
  border: 2px solid #5e9e4e;
  padding: .5rem 1.2rem;
  transition: all .2s ease;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #5e9e4e;
    width: 0;
    height: 100%;
    transition: all .2s ease;
    transform-origin: 0 100%;
    z-index: -1;
  }

  @media ${device.laptop} {
    &:hover {
      color: #fff;

      &:after {
        width: 100%;
      }
    }
  }
`

export default CtaButton;
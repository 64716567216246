import React from 'react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import CompTitle from '../helpers/comp-title';
import device from '../helpers/breakpoints';

const Visie = ({ spaceBottom }) => {
  const query = graphql`
    query {
      focus: file(relativePath: { eq: "focus.svg" }) {
        publicURL
      }
      innovatie: file(relativePath: { eq: "innovatie.svg" }) {
        publicURL
      }
      techniek: file(relativePath: { eq: "techniek.svg" }) {
        publicURL
      }
    }
  `;

  const Wrapper = styled.div`
    position: relative;
    z-index: 5;
    margin: 0 auto;
    text-align: center;
    background-color: white;
    padding-bottom: ${spaceBottom === 'big' ? 6 : 3}rem;
  `;

  const Container = styled.ul`
    display: flex;
    align-content: space-around;
    justify-content: center;
    list-style: none;
    flex-flow: row wrap;
    padding: 0;
  `;

  const CompItem = styled.li`
    flex-basis: 100%;
    align-items: center;
    margin-bottom: 4rem;

    @media ${device.laptop} {
      margin-bottom: 0;
      flex-basis: 33%;
    }
  `;

  const Paragraph = styled.p`
    padding: 0;

    @media ${device.laptop} {
      padding: 0 2rem;
    }
  `;

  const ImageWrap = styled.div`
    max-width: 100px;
    margin: 0 auto;

    img {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  `;

  const fields = [
    {
      title: 'Focus',
      description:
        'Wij hebben een ding gemeen. Wij maken Kick-ass .NET oplossingen voor onze klanten',
    },
    {
      title: 'Innovatie',
      description:
        'Wij bedenken vandaag innovatieve oplossingen voor de toekomst van onze klanten',
    },
    {
      title: 'Techniek',
      description:
        'De beste hedendaagse tools & technieken behoren tot ons arsenaal',
    },
  ];

  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <Wrapper>
          <Container>
            {fields.map((item, index) => (
              <CompItem key={index}>
                <ImageWrap>
                  <img
                    src={data[item.title.toLowerCase()].publicURL}
                    alt={item.title}
                  />
                </ImageWrap>
                <CompTitle>{item.title}</CompTitle>
                <Paragraph>{item.description}</Paragraph>
              </CompItem>
            ))}
          </Container>
        </Wrapper>
      )}
    ></StaticQuery>
  );
};

export default Visie;

import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import CompTitle from '../helpers/comp-title';
import device from '../helpers/breakpoints';

const KlantenGrid = () => {
  const query = graphql`
    {
      logos: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)/" }
          relativeDirectory: { eq: "klanten" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
      }
    }
  `;

  const Wrapper = styled.div`
    position: relative;
    margin: 0 auto;
    margin-top: 0rem;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
    color: #000;
    font-size: 1.4rem;

    @media ${device.tablet} {
      padding-bottom: 3rem;
    }
  `;

  const TitleWrapper = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
  `;

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        return (
          <Wrapper>
            <TitleWrapper>
              <CompTitle>Wij helpen</CompTitle>
            </TitleWrapper>

            {data.logos.edges.map((item, index) => {
              return (
                <div
                  key={index}
                  css={css`
                    box-shadow: 0;
                    display: block;
                    margin-bottom: 2rem;
                    margin-left: 1rem;
                    margin-right: 1rem;
                    margin-top: 0;
                    transition: 200ms box-shadow linear;
                    flex-basis: 20%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media ${device.tablet} {
                      margin: 0;
                    }
                  `}
                >
                  <img
                    src={item.node.childImageSharp.fixed.src}
                    alt="logo"
                    css={css`
                      width: 100%;
                      max-width: 175px;
                    `}
                  />
                </div>
              );
            })}
          </Wrapper>
        );
      }}
    ></StaticQuery>
  );
};

export default KlantenGrid;

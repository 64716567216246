import React from 'react';
import styled from '@emotion/styled';
import CompTitle from '../helpers/comp-title';
import '../libs/css/swiper.min.css';
import Swiper from 'react-id-swiper';
import device from '../helpers/breakpoints';
import Anchor from '../helpers/anchor';

const Banner = ({ title }) => {
  const Wrapper = styled.div`
    position: relative;
    margin: 0 auto;
    margin-top: 0rem;
    padding-bottom: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
    color: #000;
    font-size: 1.4rem;

    @media ${device.tablet} {
      padding-bottom: 3rem;
    }
  `;

  const swiperParams = {
    init: true,
    autoplay: {
      delay: 3000,
    },
    loop: true,
    speed: 300,
    spaceBetween: 30,
    slidesPerView: 1,
    slidesPerColumn: 1,
    setWrapperSize: true,
    centeredSlides: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      // when window width is >= 320px
      320: {
        spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
        spaceBetween: 20,
      },
      // when window width is >= 640px
      640: {
        spaceBetween: 30,
      },
    },
  };

  const OneSlide = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
    text-align: center;
  `;

  const TitleWrapper = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
  `;

  const Title = styled(CompTitle)`
    font-size: 1.8rem;
  `;

  const fields = [
    'Focus op maatwerk .NET software development.',
    'Hecht team met een platte organisatievorm.',
    'Zorgvuldig geselecteerde .NET experts die elkaar écht kennen.',
    'Open en directe communicatie zonder tussenschakels.',
    'Continuïteit, stabiliteit, geen veranderende manager of contactpersoon.',
    'Duidelijk in wat we doen. Samen, open, eerlijk en op basis van wederzijds vertrouwen.',
  ];

  return (
    <Wrapper id="banner">
      <Anchor id="banner-anchor"></Anchor>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>

      <Swiper {...swiperParams}>
        {fields.map((item, index) => {
          return (
            <div className="swiper-slide" key={index}>
              <OneSlide>{item}</OneSlide>
            </div>
          );
        })}
      </Swiper>
    </Wrapper>
  );
};

export default Banner;

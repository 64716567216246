import React from 'react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import LayoutContainer from '../helpers/layoutcontainer';
import device from '../helpers/breakpoints';
import CompTitle from '../helpers/comp-title';
import CtaButtonInverted from '../helpers/buttons/cta-button-inverted';
import Anchor from '../helpers/anchor';

const Techradar = ({ primary }) => {
  const query = graphql`
    query {
      images: file(relativePath: { eq: "technology_radar.png" }) {
        publicURL
      }
    }
  `;

  const Wrapper = styled.div`
    position: relative;
    background-color: #5e9e4e;
    margin: 0 auto 3rem;
    flex-flow: row wrap;
    padding: 2rem 0;

    @media ${device.laptop} {
      padding: 2rem 0;
      margin: 0 auto 6rem auto;
    }

    ::before {
      border-style: solid;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      width: 0;
      z-index: 1;
      border-color: transparent transparent #5e9e4e;
      top: -1.46vw;
      border-width: 0 0 1.5vw 100vw;
      z-index: 9;
    }

    ::after {
      border-style: solid;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      width: 0;
      z-index: 1;
      border-color: transparent #5e9e4e transparent;
      bottom: -1.46vw;
      border-width: 0 0 1.5vw 100vw;
      z-index: 9;
    }
  `;

  const ExtendedLayoutContainer = styled(LayoutContainer)`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  `;

  const Left = styled.div`
    margin: 0 auto 3rem;
    z-index: 5;
    padding: 0;
    flex: 1 0 100%;
    display: flex;
    justify-content: left;
    align-items: center;

    @media ${device.laptop} {
      padding: 0 2rem 0 0;
      margin: 0 auto;
      flex: 1 0 40%;
    }
  `;

  const Right = styled.div`
    margin: 0 auto 3rem;
    z-index: 5;
    padding: 0;
    display: flex;
    justify-content: center;
    flex: 1 0 100%;
    align-self: flex-end;

    @media ${device.laptop} {
      padding: 0 0 0 2rem;
      flex: 1 0 40%;
    }
  `;

  const ExtendedTitle = styled(CompTitle)`
    color: #fff;
  `;

  const Paragraph = styled.p`
    line-height: 1.6;
    color: #fff;
    margin-bottom: 2rem;
  `;

  const Content = styled.div`
    display: block;
    position: relative;
    z-index: 10;
    color: #fff;
  `;

  const ImageWrap = styled.div`
    margin: 0 auto;

    img {
      display: block;
      width: 80%;
      margin: 0 auto;
    }
  `;

  const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;

    @media ${device.laptop} {
      justify-content: left;
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <Wrapper id="techradar">
          <Anchor id="techradar-anchor"></Anchor>
          <ExtendedLayoutContainer>
            <Left>
              <Content>
                <ExtendedTitle>Technology radar</ExtendedTitle>
                <Paragraph>
                  Om richting te geven aan onze eigen kennisontwikkeling hebben
                  wij als .NET experts onze XPRTZ technology radar opgesteld.
                  Deze technology radar helpt ons focus te leggen op tools,
                  technieken, platformen, talen &amp; frameworks, waarvan wij
                  vinden dat iedereen deze moet kunnen dromen, waar we mee
                  experimenteren, waar we ons in moeten verdiepen of… waar wij
                  van vinden dat we afscheid van moeten nemen.
                </Paragraph>
                <ButtonWrap>
                  <CtaButtonInverted
                    href="https://radar.xprtz.net"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bekijk
                  </CtaButtonInverted>
                </ButtonWrap>
              </Content>
            </Left>
            <Right>
              <ImageWrap>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://radar.xprtz.net"
                >
                  <img src={data.images.publicURL} alt="techradar" />
                </a>
              </ImageWrap>
            </Right>
          </ExtendedLayoutContainer>
        </Wrapper>
      )}
    ></StaticQuery>
  );
};

export default Techradar;

import React from 'react';
import styled from '@emotion/styled';
import device from '../helpers/breakpoints';

const StyledSpan = styled.span`
  color: #276b44;
  display: inline-block;
  font-size: 0.875rem;
  text-decoration: none;
  position: relative;
  font-weight: 400;
  letter-spacing: -0.022em;
  margin-top: 1rem;

  @media ${device.tablet} {
    font-size: 16px;

    &:hover {
      &:after {
        width: 0;
      }
    }
  }
`;

const PostInformation = ({children}) => {
  return (
    <StyledSpan>{children}</StyledSpan>
  );
}

export default PostInformation;

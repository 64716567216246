import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import CompTitle from '../helpers/comp-title';
import CtaButton from '../helpers/buttons/cta-button';

const Insta = ({ spaceBottom }) => {
  const Wrapper = styled.div`
    background-color: white;
    margin: 0 auto;
    padding-bottom: ${spaceBottom === 'big' ? 6 : 3}rem;
  `;

  const instagramUsername = 'workwithxprtz';
  const instagramFields =
    'id,permalink,caption,media_type,media_url,timestamp,thumbnail_url';
  const [instagramMedia, setInstagramMedia] = useState(0);
  useEffect(() => {
    fetch(
      `https://graph.instagram.com/${process.env.InstagramId}/media?fields=${instagramFields}&access_token=${process.env.InstagramToken}`,
    )
      .then((response) => response.json())
      .then((result) => {
        setInstagramMedia(result.data);
      });
  }, []);

  return (
    <Wrapper
      css={css`
        background-color: white;
        margin: 0 auto;
      `}
    >
      <CompTitle
        css={css`
          display: block;
          margin-bottom: 2rem;
          text-align: center;
        `}
      >
        Ontmoet ons
      </CompTitle>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 0rem;
          flex-flow: row wrap;
        `}
      >
        {(instagramMedia || [])
          .sort(function (a, b) {
            return new Date(b.timestamp) - new Date(a.timestamp);
          })
          .slice(0, 6)
          .map((photo, index) => (
            <a
              key={index}
              href={`https://instagram.com/${instagramUsername}`}
              target="_blank"
              rel="noreferrer"
              css={css`
                box-shadow: 0;
                display: block;
                margin-bottom: 2rem;
                margin-top: 0;
                transition: 200ms box-shadow linear;
                flex-basis: 30%;

                :focus,
                :hover {
                  box-shadow: 0 2px 14px #22222244;
                  z-index: 10;
                }
              `}
            >
              <img
                key={photo.id}
                css={css`
                  width: 100%;

                  * {
                    margin-top: 0;
                  }
                `}
                src={
                  photo.media_type === 'VIDEO'
                    ? photo.thumbnail_url
                    : photo.media_url
                }
                alt={photo.caption}
              />
            </a>
          ))}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          text-transform: uppercase;
          margin: 0 auto;
        `}
      >
        <CtaButton
          target="_blank"
          href={`https://instagram.com/${instagramUsername}`}
        >
          Meer
        </CtaButton>
      </div>
    </Wrapper>
  );
};

export default Insta;
